<template>
    <div class="col-xl-3 d-flex flex-column pr-0 order-1">
        <div class="user-card gutter-10 h-auto">
            <candidate-picture
                    v-if="showAiScore"
                    :picture="candidate.user.profile_image"
                    :applicant="candidate"
            ></candidate-picture>
            <div class="user-picture" v-else>
                <img :src="candidate.user.profile_image" alt="applicant-img" class="img-fluid"/>
            </div>
            <div class="user-info">
                <h5>{{ candidate.user.name }}</h5>
                <p>{{candidate.job_title}}</p>
            </div>
            <div class="dropdown pipeline-action" :class="{ 'rejected' : isRejected, 'selected' : isSelected }">
                <submit-button :type="canOrganizeCandidate ? 'primary-button dropdown-toggle' : 'primary-button'"
                               :title="$t(candidatePipeline.name)"
                               data-toggle=""
                               aria-haspopup=""
                               aria-expanded=""
                               :click="showStage" :loading="isLoading">{{ $t(candidatePipeline.name.substring(0, 20)) }}
                    <i class="fa fa-angle-down" aria-hidden="true"></i>

                </submit-button>
                <div v-on-click-away="hideMoveToStage" class="dropdown-menu show" v-if="openMoveToStage && canOrganizeCandidate">
                    <template v-for="pipeline in pipelinesForMove">
                        <a class="dropdown-item text-break" href="#" v-if="!selected(pipeline.id) && !isRemoteInterview(pipeline.id)"
                           @click="moveCandidateToPipeline(pipeline)"
                           :class="{ 'text-danger' : rejected(pipeline.id), 'text-success' : selected(pipeline.id) }"
                        >{{ $t(pipeline.name) }}</a>
                        <a class="dropdown-item text-break" href="#" v-if="selected(pipeline.id)"
                           @click="candidateOnboard(pipeline)"
                           :class="{ 'text-danger' : rejected(pipeline.id), 'text-success' : selected(pipeline.id) }"
                        >{{ $t(pipeline.name) }}</a>
                        <a class="dropdown-item text-break" href="#" v-if="isRemoteInterview(pipeline.id)"
                           @click="selectedForRemoteInterview(pipeline)"
                           :class="{ 'text-danger' : rejected(pipeline.id), 'text-success' : selected(pipeline.id) }"
                        >{{ $t(pipeline.name) }}</a>
                    </template>
                </div>
            </div>
            <div class="user__ratting info-button-light" :class="canRemoveRating ? 'user__ratting--removable': ''" @click="removeRating()">
                <i class="eicon e-star"></i> <span id="user__ratting__point">{{ candidate.rating }}</span>
            </div>
        </div>
        <div class="user-card align-items-baseline gutter-10">
            <ul class="user-info user-info__list">
                <li>
                    <div class="user-icon">
                        <i class="eicon e-user"></i>
                    </div>
                    <div class="user-text">
                        <p class="user-text__label">{{ $t("First name")}}</p>
                        <p>{{ candidate.user.first_name }}</p>
                    </div>
                </li>
                <li>
                    <div class="user-icon">
                        <i class="eicon e-user"></i>
                    </div>
                    <div class="user-text">
                        <p class="user-text__label">{{ $t("Last name")}}</p>
                        <p>{{ candidate.user.last_name }}</p>
                    </div>
                </li>
                <li>
                    <div class="user-icon">
                        <i class="eicon e-mail"></i>
                    </div>
                    <div class="user-text">
                        <p class="user-text__label text-capitalize">{{ $t("email address")}}</p>
                        <p class="user-email">{{ candidate.user.email }}</p>
                    </div>
                </li>
                <li>
                    <div class="user-icon">
                        <i class="eicon e-phone"></i>
                    </div>
                    <div class="user-text">
                        <p class="user-text__label text-capitalize">{{ $t("phone number")}}</p>
                        <p>{{ candidate.user.mobile_number }}</p>
                    </div>
                </li>
                <li>
                    <div class="user-icon">
                        <i class="eicon e-calender"></i>
                    </div>
                    <div class="user-text">
                        <p class="user-text__label">{{ $t("Date of Application")}}</p>
                        <p v-html="dateConvertToSpan(candidate.created_at)"></p>
                    </div>
                </li>
                <li>
                    <div class="user-icon">
                        <i class="eicon e-star"></i>
                    </div>
                    <div class="user-text">
                        <p class="user-text__label">{{ $t("Rate")}}</p>
                        <Rating v-if="showRating" :applicant="candidate" :rate="candidate.rating"></Rating>
                    </div>
                </li>
                <li>
                    <div class="user-icon">
                        <i class="eicon e-share"></i>
                    </div>
                    <div class="user-text">
                        <p class="user-text__label">{{ $t("Social Profiles")}}</p>
                        <div class="mt-2">
                            <a v-for="profile in candidate.user.social_profiles" :href="profile.link" target="_blank" class="social-button semi-button-primary"><i class="eicon" :class="`e-${profile.type.toLowerCase()}`"></i></a>
                        </div>
                    </div>
                </li>
            </ul>
            <button class="semi-button semi-button-info mt-5 w-100" v-if="company.package_rule.messaging && canOrganizeCandidate" @click="$emit('emailCandidate')">{{ $t("Email")}} {{ candidate.user.name }}</button>
        </div>
        <modal-onboarding
                v-if="showOnboardingModal"
                :applicant-ids="[this.candidate.id]"
                :job-id="candidate.job_slug"
                @closeModal="closeOnboardingModal"
                @afterComplete="moveCandidateToPipeline(selectedPipeline)"
        />
        <remote-interview
                v-if="showRemoteInterview"
                :applicant-ids="[this.candidate.id]"
                :job-id="candidate.job_slug"
                @remote_interview_closed="closeRemoteInterview()"
                @remote_interview_moved ="movedToRemoteInterview()"
        ></remote-interview>
    </div>
</template>
<script>
    import {EventBus} from "@/extra/event-bus";
    import {CANDIDATE_PIPELINE_CHANGED, CANDIDATE_RATING_ADDED} from "@/constants/events";
    import {mapState} from 'vuex';
    import {DEFAULT_PIPELINE, PERMISSIONS} from "@/constants/enums";
    import client from "../../../app/api/Client";
    import {getApplicantExists} from "@/app/api/CommonRequest";
    import { dateConvertSpan } from '@/extra/helper';
    import {canOrganizeCandidate} from "@/config/permission";
    const ModalOnboarding  = () => import("../../modal/job/Onboarding.vue");
    const RemoteInterview  = () => import("../../modal/job/RemoteInterview.vue");
    const CandidatePicture  = () => import("../CandidatePicture.vue");
    const Rating  = () => import("../../common/Rating.vue");
    import { directive as onClickaway } from 'vue-clickaway';

    export default {
        components: {
            CandidatePicture,
            Rating,
            ModalOnboarding,
            RemoteInterview
        },
        directives: {
          onClickaway: onClickaway,
        },
        data() {
            return {
                showOnboardingModal: false,
                isLoading: false,
                openMoveToStage: false,
                selectedApplicant: this.candidate.id,
                selectedPipeline: null,
                showRating: true,
                showRemoteInterview: false,
            }
        },
        computed: {
            ...mapState(['company']),
            canRemoveRating() {
                return this.candidate.rating>0;
            },
            pipelines() {
                return this.candidate.job_pipelines;
            },
            pipelinesForMove() {
                let pi = _.filter(this.pipelines, (p) => p.id != this.candidate.pipeline_index);
                return pi;
            },
            isRejected() {
                return this.candidate.pipeline_index === DEFAULT_PIPELINE.REJECTED;
            },
            isSelected() {
                return this.candidate.pipeline_index === DEFAULT_PIPELINE.SELECTED;
            },
            candidatePipeline() {
                return _.find(this.candidate.job_pipelines, p => p.id == this.candidate.pipeline_index) || _.first(this.candidate.job_pipelines);
            },
            showAiScore() {
                return (
                    this.company.showAiScore
                    && this.candidate.showAiScore
                );
            },
            canOrganizeCandidate() {
                return canOrganizeCandidate();
            },
        },
        methods: {
            dateConvertToSpan(date) {
                return dateConvertSpan(date);
            },
            async removeRating() {
                if(!this.canOrganizeCandidate) {
                    return false;
                }
                if (!this.candidate.rating) {
                    return false;
                }
                let confirmMessage = {
                    title: this.$t("Confirmation"),
                    body: this.$t("Are you sure, you want to remove rating for this candidate?")
                };
                await this.$dialog.confirm(confirmMessage).then(() => {
                     this.ratingRemoved();
                });
            },
            async ratingRemoved() {
                this.showRating = false;

                try {
                    let {data: {data, message}} = await client().put(`/job/applicant/${this.selectedApplicant}/rating`, {
                        'rating': 0
                    });
                    this.candidate.rating = data.rating;
                    this.$toast.success(this.$t(`Rating removed`));
                    EventBus.$emit(CANDIDATE_RATING_ADDED, data);
                } catch (e) {
                }
                this.showRating = true;
            },
            hideMoveToStage() {
                this.openMoveToStage = false;
            },
            showStage() {
                getApplicantExists(this.candidate.id).then(()=>{}).catch(()=>{});
                this.openMoveToStage = !this.openMoveToStage
            },
            async moveCandidateToPipeline(pipeline) {
                this.openMoveToStage = false;
                if (this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                    this.isLoading = true;
                    try {
                        let {data: {data, message}} = await client().post(`/job/${this.candidate.job_slug}/pipeline/candidate`, {
                            applicants: [this.selectedApplicant],
                            pipeline: pipeline.id
                        });
                        this.candidate.pipeline = pipeline.name;
                        this.candidate.pipeline_index = pipeline.id;
                        this.$toast.success(this.$t(message));
                        EventBus.$emit(CANDIDATE_PIPELINE_CHANGED);
                        this.closeOnboardingModal();
                    } catch (error) {
                        this.$toast.error(this.$t(error.response.data.message));
                        if (error.response.status === 480 || error.response.status === 490) {
                            this.changePipeline(this.activeStage);
                        }
                    }
                    this.isLoading = false;
                } else {
                    this.$toast.error(this.$t(`Unauthorized access`));
                }
            },
            candidateOnboard(pipeline) {
                if (this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                    if (pipeline.id === DEFAULT_PIPELINE.SELECTED) {
                        this.showOnboardingModal = true;
                        this.selectedPipeline = pipeline;
                    }
                } else {
                    this.$toast.error(this.$t(`Unauthorized access`));
                }
            },
            closeOnboardingModal() {
                this.showOnboardingModal = false;
            },
            selected(pipelineId) {
                return pipelineId === DEFAULT_PIPELINE.SELECTED;
            },
            rejected(pipelineId) {
                return pipelineId === DEFAULT_PIPELINE.REJECTED;
            },
            isRemoteInterview(pipelineId) {
                return pipelineId === DEFAULT_PIPELINE.REMOTE_INTERVIEW;

            },
            selectedForRemoteInterview() {
                this.showRemoteInterview = true;
            },
            closeRemoteInterview() {
                this.showRemoteInterview = false;
            },
            movedToRemoteInterview(){
                this.closeRemoteInterview();
                this.candidate.pipeline = "Remote Interview";
                this.candidate.pipeline_index = DEFAULT_PIPELINE.REMOTE_INTERVIEW;
            },
        },
        props: ['candidate'],
        mounted() {
            EventBus.$on(CANDIDATE_RATING_ADDED, (data) => this.candidate.rating = data.rating);
        },

    }
</script>

<style scoped>
    .dropdown-menu{
        margin-top: 45px;
    }
    @media all and (max-width: 767px) {
        .user-card .semi-button.mt-5 {
            margin-top: 0 !important;
        }
    }
</style>
